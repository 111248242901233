html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/* Sections
   ========================================================================== */
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

ul, li {
  list-style-type: none;
}

.hedden {
  display: none;
}

img {
  border-style: none;
  max-width: 100%;
}

/* Forms
   ========================================================================== */
hr,
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

/*==========  Desktop First Method  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  /**/
}

/* Medium Devices, Desktops */
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  /* Disable Animation on Mobile Devices */
  .animated {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  .block3 .btn-wrap {
    position: relative;
    top: 20px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  /**/
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 425px) {
  .block3 {
    padding-top: 190px;
  }
  .block11 .timer-wrap {
    padding: 15px 5px 30px;
  }
  .block11 .form-wrap {
    padding: 30px 15px;
  }
}

/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .block6-inner {
    max-width: 100%;
  }
  .block6 .comments-list_item {
    margin-right: 60px;
  }
  .block6 .comments-list_item:before {
    content: "";
    display: block;
    position: absolute;
    background: url("../img/quotes.png") no-repeat 0 0;
    width: 32px;
    height: 27px;
    right: -40px;
    top: 0;
  }
  .block7-inner .gellary-item {
    height: 110px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .block2-frame {
    background: #e2b320;
    /* Old browsers */
    background: -moz-linear-gradient(top, #e2b320 0%, #e2b320 74%, #be0026 74%, #e2b320 74%, #be0026 74%, #be0026 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e2b320 0%, #e2b320 74%, #be0026 74%, #e2b320 74%, #be0026 74%, #be0026 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e2b320 0%, #e2b320 74%, #be0026 74%, #e2b320 74%, #be0026 74%, #be0026 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2b320', endColorstr='#be0026',GradientType=0 );
    /* IE6-9 */
  }
  .block2-frame:after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 5px solid #be0026;
    border-radius: 6px;
    border-top: none;
    border-left: none;
    transform: rotateZ(45deg);
    bottom: -16px;
    margin-left: -20px;
    left: 50%;
  }
  .block2 .plus-list {
    display: table;
  }
  .block2 .plus-list_item {
    display: table-cell;
    vertical-align: top;
    padding: 110px 10px 0;
    width: 25%;
  }
  .block3-results {
    max-width: 700px;
    margin: 0 auto;
  }
  .block5-inner {
    max-width: 700px;
    margin: 0 auto;
  }
  .block8-inner .htu-list_item {
    display: inline-block;
    width: 30%;
  }
  .block10-inner {
    max-width: 700px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .only_web {
    display: block;
  }
  .logo-wrap {
    margin-top: 0;
  }
  .nav {
    max-width: 100%;
    margin: 0 auto;
  }
  .nav-iconMenu {
    display: none;
  }
  .nav-inner {
    display: inline-block;
    vertical-align: middle;
    width: 190px;
  }
  .nav-menu {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
  }
  .nav-menu ul {
    display: table;
    table-layout: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    background: transparent;
    min-height: 90px;
  }
  .nav-menu ul li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
    text-align: center;
    border-bottom: 1px solid transparent;
  }
  .nav-menu ul li:hover {
    border-bottom: 1px solid #be0026;
  }
  .block1 {
    min-height: 900px;
  }
  .block1-inner h3 {
    font-size: 60px;
  }
  .block1-inner h4 {
    font-size: 22px;
  }
  .block1-inner .head-quote {
    padding: 0 45px;
    margin: 30px auto;
    border: 3px solid #444547;
    border-radius: 5px;
    border-top: none;
  }
  .block1-inner .head-quote:after, .block1-inner .head-quote:before {
    content: "";
    display: block;
    position: absolute;
    border-top: 3px solid #444547;
    width: 43px;
    top: 0;
  }
  .block1-inner .head-quote:after {
    right: 0;
  }
  .block1-inner .head-quote:before {
    left: 0;
  }
  .block1-inner .head-quote_tit {
    position: relative;
    top: -8px;
    margin-bottom: 0;
  }
  .block1-inner .head-quote p {
    padding: 20px 25px;
  }
  .block1-inner .price {
    display: table;
    height: 126px;
    min-width: 126px;
    background: #e2b320;
    border-radius: 50% 50% 50% 0;
    color: #424242;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: 85%;
    top: -15px;
  }
  .block1-inner .price-inner {
    display: table-cell;
    vertical-align: middle;
    padding: 10px;
    height: 100%;
    width: 100%;
  }
  .block1-inner .price-inner .old-price {
    color: #FA0505;
    text-decoration: line-through;
  }
  .block1-inner .price-inner .new-price span {
    font-size: 44px;
  }
  .block2 h3 {
    font-size: 30px;
    line-height: 1;
  }
  .block2 h3 span {
    font-size: 60px;
  }
  .block2-frame_inner {
    padding: 30px 5px;
  }
  .block3 {
    min-height: 1000px;
    margin-top: -250px;
    padding: 0;
  }
  .block3-inner {
    padding-top: 300px;
  }
  .block3 h3 {
    font-size: 30px;
    line-height: 1;
  }
  .block3 h3 span {
    font-size: 60px;
  }
  .block3-results {
    display: table;
    width: 100%;
    max-width: 100%;
  }
  .block3 .result-list {
    display: table-cell;
    width: 50%;
    margin-left: 0;
  }
  .block3 .result-list_item {
    padding: 0 20px 0 85px;
  }
  .block3 .result-list_item:nth-of-type(1):before {
    left: 0;
  }
  .block3 .result-list_item:nth-of-type(2):before {
    left: 0;
  }
  .block3 .result-list:last-of-type .result-list_item:nth-of-type(1):before {
    left: 0;
  }
  .block3 .result-list:last-of-type .result-list_item:nth-of-type(2):before {
    left: 0;
  }
  .block3 .btn-wrap {
    margin-top: 40px;
  }
  .block4 {
    background: url("../img/offer_bg.png") no-repeat center/cover;
    padding: 130px 0 100px;
    margin-top: -155px;
    text-align: center;
  }
  .block4-inner {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    max-width: 560px;
    text-align: left;
  }
  .block4-inner h5 {
    font-size: 53px;
  }
  .block4-inner h6 {
    font-weight: 30;
    font-weight: 700;
  }
  .block4-inner p {
    font-size: 22px;
  }
  .block4-inner .price {
    font-size: 30px;
  }
  .block4-inner .price .new-price {
    font-size: 47px;
  }
  .block4-inner .btn-wrap {
    border: none;
  }
  .block4 .img-box {
    display: inline-block;
    vertical-align: middle;
    max-width: 400px;
    text-align: center;
  }
  .block5-inner {
    max-width: 100%;
    padding: 65px 65px 0;
  }
  .block5-inner h3 {
    font-size: 60px;
  }
  .block5-inner p {
    font-size: 22px;
    font-weight: 700;
  }
  .block5-inner .logo {
    margin: 75px auto 15px;
  }
  .block6-inner h3 {
    font-size: 60px;
  }
  .block6 .comments {
    display: table;
    width: 100%;
  }
  .block6 .comments-list {
    display: table-cell;
    width: 50%;
    padding-left: 95px;
  }
  .block6 .comments-list_item .author-ava {
    width: 65px;
    height: 65px;
    left: -75px;
  }
  .block6 .comments-list_item p {
    line-height: 1.6;
  }
  .block7 {
    overflow: hidden;
  }
  .block7-inner {
    width: 1980px;
  }
  .block7-inner .gellary {
    width: 1980px;
    max-width: 100%;
    padding: 0;
  }
  .block7-inner .gellary-item {
    height: 190px;
    width: auto;
  }
  .block7-inner .gellary-item:first-child {
    width: auto;
  }
  .block7 .container {
    width: 1980px;
    max-width: 100%;
  }
  .block8 h3 {
    font-size: 60px;
    line-height: 1;
  }
  .block8 .img-box {
    display: inline-block;
    vertical-align: middle;
    width: 30%;
  }
  .block8 .htu-inner {
    display: inline-block;
    vertical-align: middle;
    width: 65%;
  }
  .block9-inner {
    max-width: 100%;
  }
  .block9-inner h3 {
    line-height: 1;
  }
  .block9-inner h3 span {
    font-size: 60px;
    display: block;
  }
  .block9 .catalog-item {
    max-width: 200px;
  }
  .block10-inner h3 {
    font-size: 60px;
  }
  .block11-part {
    display: inline-block;
    vertical-align: middle;
    width: 49%;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  /**/
}
