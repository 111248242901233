@import '_var.scss';
@import '_mixin.scss';
@import '_sprites.scss';
@import '_base.scss';

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	/**/
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px){

}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px){
	
	/* Disable Animation on Mobile Devices */
	.animated{
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
	//--------------------------------------
	//			Block 3
	//-------------------------------------- 

	.block3 {
		.btn-wrap {
			position: relative;
			top: 20px;
		}
	}

}
/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px){
	/**/
}


/* Custom, iPhone Retina */
@media only screen and (max-width : 425px){
	
	//--------------------------------------
	//			Block 3
	//-------------------------------------- 

	.block3 {
		padding-top: 190px;
	}


	.block11 {
		.timer-wrap {
			padding: 15px 5px 30px;
		}
		.form-wrap {
			padding: 30px 15px;
		}
	}
}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px){
	
	
}


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px){
	
	
	//--------------------------------------
	//			Block 7
	//--------------------------------------

	.block6 {
		&-inner {
			max-width: 100%;
		}
		.comments {
			&-list {
				&_item {
					margin-right: 60px;
					&:before {
						@include pseudo;
						background: url('../img/quotes.png') no-repeat 0 0;
						width: 32px;
						height: 27px;
						right: -40px;
						top: 0;
					}
				}
			}
		}
	}

	//--------------------------------------
	//			Block 7
	//--------------------------------------
	.block7 {
		&-inner {
			.gellary{
				&-item {
					height: 110px;
				}
			}
		}
	}

}


/* Small Devices, Tablets */
@media only screen and (min-width : 768px){
	
	//--------------------------------------
	//			Block 2
	//--------------------------------------
	
	.block2 {
		&-frame {
			background: #e2b320; /* Old browsers */
            background: -moz-linear-gradient(top, #e2b320 0%, #e2b320 74%, #be0026 74%, #e2b320 74%, #be0026 74%, #be0026 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #e2b320 0%,#e2b320 74%,#be0026 74%,#e2b320 74%,#be0026 74%,#be0026 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #e2b320 0%,#e2b320 74%,#be0026 74%,#e2b320 74%,#be0026 74%,#be0026 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2b320', endColorstr='#be0026',GradientType=0 ); /* IE6-9 */
            &:after {
            	@include pseudo;
            	width: 30px;
            	height: 30px;
            	background: #fff;
            	border: 5px solid #be0026;
            	border-radius: 6px;
            	border-top: none;
            	border-left: none;
            	transform: rotateZ(45deg);
            	bottom: -16px;
            	margin-left: -20px;
            	left: 50%;
            } 
		}
		.plus {
			&-list {
				display: table;
				&_item {
					display: table-cell;
					vertical-align: top;
					padding: 110px 10px 0;
					width: 25%;
				}
			}
		}
		
	}
	//--------------------------------------
	//			Block 3
	//-------------------------------------- 

	.block3 {
		&-results {
			max-width: 700px;
			margin: 0 auto;
		}
	}

	//--------------------------------------
	//			Block 5
	//-------------------------------------- 

	.block5 {
		&-inner {
			max-width: 700px;
			margin: 0 auto;
		}
		
	}
	//--------------------------------------
	//			Block 8
	//--------------------------------------
	
	.block8 {
		&-inner {
			.htu-list_item {
				display: inline-block;
				width: 30%;
			}
		}
	}
	//--------------------------------------
	//			Block 10
	//--------------------------------------
	.block10 {
		&-inner {
			max-width: 700px;
		}
	}
}


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px){
	.only_web {
		display: block;
	}
	//--------------------------------------
	//			Block 1
	//--------------------------------------

	.logo-wrap {
		margin-top: 0;
	}
	.nav {
		max-width: 100%;
		margin: 0 auto;
		&-iconMenu {
			display: none;
		}
		&-inner {
			display: inline-block;
			vertical-align: middle;
			width: 190px;
		}
		&-menu {
			display: inline-block;
			vertical-align: middle;
			width: 80%;
			ul {
				display: table;
				table-layout: auto;
				margin: 0 auto;
				position: relative;
				top: auto;
				background: transparent;
				min-height: 90px;
				li {
					display: table-cell;
					vertical-align: middle;
					padding: 0 10px;
					text-align: center;
					border-bottom: 1px solid transparent;
					&:hover {
						border-bottom: 1px solid $clr-red;
					}
				}
			}			
		}
	}
	.block1 {
		min-height: 900px;
		&-inner {
			h3 {
				font-size: 60px;
			}
			h4 {
				font-size: 22px;
			}
			.head-quote {
				padding: 0 45px;
				margin: 30px auto;
				border: 3px solid #444547;
				border-radius: 5px;
				border-top: none;
				&:after, &:before {
					@include pseudo;
					border-top: 3px solid #444547;
					width: 43px;
					top: 0;
				}
				&:after {
					right: 0;
				}
				&:before {
					left: 0;				 
				}
				&_tit {				
					position: relative;
					top: -8px;
					margin-bottom: 0
				}
				p {
					padding: 20px 25px;

				}
			}
			.price {
				display: table;
				height: 126px;
				min-width: 126px;
				background: $clr-yellow;
				border-radius: 50% 50% 50% 0;
				color: $clr-grey;
				font-size: 20px;
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
				position: absolute;
				left: 85%;
				top: -15px;
				&-inner {
					display: table-cell;
					vertical-align: middle;
					padding: 10px;
					height: 100%;
					width: 100%;
					.old-price {
						color: #FA0505;
						text-decoration: line-through;
					}
					.new-price {
						span {
							font-size: 44px;
						}
					}
				}

			}
		}
	}
	
	//--------------------------------------
	//			Block 2
	//-------------------------------------- 
	
	.block2 {
		h3{
			font-size: 30px;
			line-height: 1;
			span {
				font-size: 60px;
			}
		}
		&-frame {
			&_inner {
				padding: 30px 5px;
			}
		}
	}

	//--------------------------------------
	//			Block 3
	//-------------------------------------- 

	.block3 {
		min-height: 1000px;
		margin-top: -250px;
		padding: 0;
		&-inner {
			padding-top: 300px;
		}
		h3{
			font-size: 30px;
			line-height: 1;
			span {
				font-size: 60px;
			}
		}
		&-results {
			display: table;
			width: 100%;
			max-width: 100%;
		}
		.result-list {
			display: table-cell;
			width: 50%;
			margin-left: 0;
			&_item {
				padding: 0 20px 0 85px;				
				&:nth-of-type(1){
					&:before {
						left: 0;
					}
				}
				&:nth-of-type(2){
					&:before {
						left: 0;
					}
				}
			}
			&:last-of-type {
				.result-list {
					&_item {					
						&:nth-of-type(1){
							&:before {
								left: 0;
							}
						}
						&:nth-of-type(2){
							&:before {
								left: 0;
							}
						}
					}
				}
			}
		}
		.btn-wrap {
			margin-top: 40px;
		}

	}
	//--------------------------------------
	//			Block 4
	//--------------------------------------

	.block4 {
		background: url('../img/offer_bg.png') no-repeat center/ cover;
		padding: 130px 0 100px;
		margin-top: -155px;
		text-align: center;
		&-inner {
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;
			max-width: 560px;
			text-align: left;
			h5 {
				font-size: 53px;
			}
			h6 {
				font-weight: 30;
				font-weight: 700;
			}
			p {
				font-size: 22px;
			}
			.price {
				font-size: 30px;
				.new-price {
					font-size: 47px;
				}
			}
			.btn-wrap {
				border: none;
			}
		}
		.img-box {
			display: inline-block;
			vertical-align: middle;
			max-width: 400px;
			text-align: center;
		}
	}

	//--------------------------------------
	//			Block 5
	//--------------------------------------

	.block5 {
		&-inner {
			max-width: 100%;
			padding: 65px 65px 0;
			h3 {
				font-size: 60px;
			}
			p {
				font-size: 22px;
				font-weight: 700;
			}
			.logo {
				margin: 75px auto 15px;
			}
		}
		
	}
	
	//--------------------------------------
	//			Block 6
	//--------------------------------------

	.block6 {
		&-inner {
			h3 {
				font-size: 60px;
			}
		}
		.comments {
			display: table;
			width: 100%;
			&-list {
				display: table-cell;
				width: 50%;
				padding-left: 95px;
				&_item {
					.author-ava {
						width: 65px;
						height: 65px;
						left: -75px;
					}
					p {
						line-height: 1.6;
					}
				}
			}
		}
	}

	//--------------------------------------
	//			Block 7
	//--------------------------------------
	

	.block7 {
		overflow: hidden;
		&-inner {
			width: 1980px;
			.gellary{
				width: 1980px;
				max-width: 100%;
				padding: 0;
				&-item {
					height: 190px;
					width: auto;
					&:first-child {
						width: auto;
					}
				}
			}
		}
		.container {
			width: 1980px;
			max-width: 100%;
		}
	}
	
	
	//--------------------------------------
	//			Block 8
	//--------------------------------------
	
	.block8 {
		h3 {
			font-size: 60px;
			line-height: 1;
		}
		.img-box {
			display: inline-block;
			vertical-align: middle;
			width: 30%;
		}
		.htu-inner {
			display: inline-block;
			vertical-align: middle;
			width: 65%;
		}
	}
	
	//--------------------------------------
	//			Block 9
	//--------------------------------------

	.block9 { 
		&-inner {
			max-width: 100%;
			h3 {
				line-height: 1;
				span {
					font-size: 60px;
					display: block;
				}				
			}
		}
		.catalog {
			&-item {
				max-width: 200px;
			}
		}
		
	}
	
	//--------------------------------------
	//			Block 10
	//--------------------------------------

	.block10{
		&-inner {
			h3 {
				font-size: 60px;
			}
		}
	}

	//--------------------------------------
	//			Block 11
	//--------------------------------------


	.block11 {
		&-part {
			display: inline-block;
			vertical-align: middle;
			width: 49%;
		}

	}


}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px){
	/**/
}

